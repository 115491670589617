.nav_bar{
    /* height: 10vh; */
    z-index: 999999999;
   transition: all .2s ease;
    /* background-color: rgb(198, 255, 236); */
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100vw;
    padding-top: 10px;
    padding-bottom: 10px;
}
.h20vh{
    height: 20vh;
}
.humb{
    width: 30px;
    height: 3px;
    background-color: black;
    margin-top: 4px;
}
.bw{
    background-color: white;
}
.displayOnPhoneDisplay{
    display: none;
}
.links_div{
    flex-wrap: wrap;
}

.links{
    margin-left: 30px; 
    font-size: 1.3rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    color: black;
}
.linksphone{
   margin-bottom: 30px;
    font-size: 1.3rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    color: black;
}
.Logo{
    max-height: 100px;
}


/* nav phone  */

.sideNavOpen{
    height: 100vh;
    width: 80%;
    /* background-color: white; */
    position: fixed;
    top: 0;
    left: 0;
    transition: all .5s ease;
}
.iconOnClickin{
    margin-top: 50px;
    margin-bottom: 50px;
}
.sideNavClose{
    height: 100vh;
    width: 0;
    /* background-color: white; */
    position: fixed;
    top: 0;
    left: 0;
    transition: all .5s ease;
visibility: hidden;
display: none;
}