.openingImage{
    height: 100vh;
    width: 100%;
    position: relative;
}
.w500{ 
    width: 50%;
}
.posrealetive{
    position: relative;
}
.bgImage{
    height: 65%;
    width: 90%;
    background-image: url(../images/1.jpg);
    object-fit: cover;
    background-size: cover;
    z-index: 99999;
    margin-top: 50px;
    position: relative;
    animation: imageanimation 20s infinite linear;
    /* box-shadow: 0 3px 20px  black; */
/* border-radius: 19% 81% 6% 94% / 82% 8% 92% 18% ; */
}
@keyframes imageanimation {
    0%{
        background-image: url(../images/10.JPG);

    }
    19%{
        background-image: url(../images/10.JPG);

    }
    20%{
        background-image: url(../images/11.JPG);

    }
    39%{
        background-image: url(../images/11.JPG);

    }
    40%{
        background-image: url(../images/6.jpg);

    }
    59%{
        background-image: url(../images/6.jpg);
 
    }
    60%{
        background-image: url(../images/8.JPG);

    }
    79%{
        background-image: url(../images/8.JPG);

    }
    80%{
        background-image: url(../images/9.JPG);

    }
   
    100%{
        background-image: url(../images/9.JPG);

    }
    
    
}
.square_01{
    height: 200px ;
 
    width: 200px;
    background-color: #D3525F;
    position: absolute;
    top: 11%;
    right: 1%;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
    /* box-shadow: 0 3px 10px  black; */
    
    
}
.square_01::after{
    content: '';
    padding-bottom: 100%;
    
}
.inside_circle_01{
    height: 100px;
    width: 100px;
    background-color: white;
    margin-left: 10px;
    margin-top: 50px;
}
.inside_circle_02{
    height: 200px;
    width: 200px;
    background-color: white;
    margin-left: 10px;
    margin-top: 50px;
    position: absolute;
    bottom: 20px;
    right: 0px;
}
.shape_01{
    border-radius: 40% 60% 31% 69% / 74% 58% 42% 26%  ;
}
.shape_02{
  border-radius:  50% ;
}
.square_03{
    /* box-shadow: 0 3px 10px  black; */

    height: 400px ;
    width: 400px;
    background-color: #FBB040;
    position: absolute;
    bottom:  1%;
    right:  -5%;
    border-radius: 69% 31% 31% 69% / 30% 93% 7% 70% ;
}
.square_02{
    height: 600px ;
    width: 300px;
    background-color: #359272;
    position: absolute;
    top: 11%;
    left: -9%;
    /* box-shadow: 0 3px 10px  black; */

    border-radius: 69% 31% 31% 69% / 30% 93% 7% 70% ;
}

.redBg{
    background-color: #D3525F;

}
.yellowBg{
    background-color: #FBB040;
}
.header{
    position: absolute;
    bottom: -50px;
    right: -50px;
    font-size: 8rem;
color: #262626;
}
.imageLogo{
    max-height: 400px;
   
}
/* a77254 */
/* #B5866A */
/* #8F8A6C */


/* new opening page 
 */
.absulot{
    position: absolute;
}

 .imageOpenWeb{
    height: 100vh;
    width: 100%;
    background-image: url(../images/new/out2.jpg);
    object-fit: cover;
    background-size: cover;
    z-index: 99999;

    position: relative;
    animation: imageanimation 20s infinite linear;
 }


@keyframes imageanimation {
    0%{
        background-image: url(../images/new/out2.jpg);

    }
    19%{
        background-image: url(../images/new/out2.jpg);

    }
    20%{
        background-image: url(../images/new/cup.jpg);

    }
    39%{
        background-image: url(../images/new/cup.jpg);

    }
    40%{
        background-image: url(../images/new/in2.jpg);

    }
    59%{
        background-image: url(../images/new/in2.jpg);
 
    }
    60%{
        background-image: url(../images/new/good.jpg);

    }
    79%{
        background-image: url(../images/new/good.jpg);

    }
    80%{
        background-image: url(../images/new/dog.jpg);

    }
   
    100%{
        background-image: url(../images/new/dog.jpg);

    }
    
    
}


.LogoImage{ 
    max-height: 100px;
}
.logosvg{
    height: 100px;
    width: 150px;
}



/* .image-gallery {
    position: relative;
  } */
  
  .image-gallery img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    height: 100%;
    width: 100%;
  }
  
  .image-gallery img.active {
    opacity: 1;
  }