.aboutsec{
    min-height: 100vh;
 overflow: hidden;
}
.square_about_1{
    height: 50%;
    width: 45%;
    background-color: #d3525f43;
    position: absolute;
    bottom: 0;
    right: 0;
   border-radius: 4px;
}
.wordsSec{
    align-items: flex-end;
    
}
.wordsText{
    text-align: right;
    font-size: 1.8rem;
    font-weight: 700;
    direction: rtl;
    margin-bottom: 10px;
    /* color: rgb(50, 49, 49); */
}
.textWords{
    direction: rtl;
/* color: rgb(58, 58, 58); */
    text-align: right;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 10px;
}
.imageDivAbout{
    height: 50%;
 margin-left: 40px;
    width: 40%;
    margin-top: 50px;
}
.min_height{
    /* padding-bottom: 100%; */
    /* min-height: 500px; */
    object-fit: cover;
    background-size: cover;
}
.img{
    height: 100%;
    width: 100%;
}

.about_header{
    font-weight: 700;
    font-size: 3rem;
    background-image: linear-gradient(to right,#fbb040 ,#359272 ,#d3525f );
    background-clip: text ;
    color: transparent;
}
.square_about_2{
    height: 50%;
    width: 30%;
    background-color: #fbb04055;
    position: absolute;
    top: 40%;
    left: 0%;
   border-radius: 4px;
}
.square_about_3{
    height: 25%;
    width: 40%;
    background-color: #35927247;
    position: absolute;
    top: 0%;
    left: 0%;
   border-radius: 4px;
}

/* new page about 
 */
.bw{
    background-color: rgb(255, 255, 255);
}

 .aboutNew{
    /* height: 100vh; */
    width: 100%;
padding-bottom: 100px;

 }
 .opacity0{
    opacity: 0;
    transition: all .3s ease; 
}
.opacity1{
    opacity: 1;
    transition: all .3s ease;
}
 .imageSideAbout{
max-height: 300px;
min-height: 300px;

background-image: url('../images/new/out2small210.jpg');
object-fit: cover;
background-size: cover;
overflow: hidden;
}
 .wordsSectionAbout{
    margin-left: 50px;
 }
 .padd{
    padding-right: 15px;
 }
 .aboutNew{
    padding-top: 100px;
 }
 .posrel{
    position: relative;
 }

 .square_about_2_new{
    height: 60%;
    width: 45%;
    background-color: #fbb04055;
    position: absolute;
    bottom: -25%;
    left: 50%;
   border-radius: 50%;
   z-index: -1;
}
.square_about_3_new{
    height: 50%;
    width: 40%;
    background-color: #35927247;
    position: absolute;
    top: -15%;
    left: -10%;
   border-radius: 50%;
   z-index: -1;
}

.square_about_1_new{
    height: 60%;
    width: 40%;
    background-color: #d3525f43;
    position: absolute;
    bottom: -10%;
    left: -15%;
    z-index: -1;
   border-radius: 4px;
}


/* about page style  */
.maximg{
  width: 100%;
}
.maximg::after{
    content: "";
    display: block;
    padding-bottom: 100%;
  }
.grinAbout{
    display: grid ;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.imageSideRow{
    width: 100%;
}


.aboutScreen{
    min-height: 100vh;
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.85),rgba(255, 255, 255, 0.573)),url('../images/new/good.jpg'); */
padding-bottom: 100px;
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.85),rgba(255, 255, 255, 0.573)),url('../images/new/frame.png'); */
    /* background-position: center; */
    background-size: cover;
    object-fit: cover;
}
.imageSide{
width: 100%;

/* transform: translateY(100px); */
}
.wordsSide{
/* 
    margin-left: 50px; */
   
}
.marginLeft50{
    margin-left: 50px;
}

.aboutHeaderPage{
    /* color: #262626; */
}



.gridImage{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

}
.imgabout{
    max-width: 400px;

}
.textWordsPage{
    margin-top: 20px;
    font-size: 1.5rem;
   
    /* background-color: white; */
    padding: 3px;
   width: 100%;
}
.textWordsPageE{
    margin-top: 20px;
    font-size: 1.5rem;
    /* background-color: white; */
    padding: 3px;
  
   margin-right: 50px;
}

.padab{
    padding-top: 100px;
    padding-bottom: 100px;
}