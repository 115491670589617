*{
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
}
h1{
  color: #262626 !important;
}

/* @media screen and (max-width: 1375px) {
.grinAbout{
grid-template-rows: 1fr 1fr;
}
} */
@media screen and (max-width: 892px) {
*{
  overflow-x: hidden !important;
}
.imageSideAbout{
  max-height: 248px !important;
  min-height: 248px !important;
}
.cols{
  grid-template-columns: 1fr 1fr !important;
}
.marginLeft50{
  margin-left: 0 !important;
}
.textWordsPageE {
  margin-right: 0 !important;
}
  .displayNone{
    display: none !important;
  }
  .flexRowToColRevers{
    flex-direction: column-reverse !important;
  }
  .marginLeft{
    margin-left: 0 !important;
  }
  .sideA{
    height: 300px !important;
    width: 100% !important;

    
}
.sideB{
    width: 100% !important;
}
  .Logo{
    height: 60px;
  }
  .iframeMap{
width: 100% !important;
  }
  .displayOnPhoneDisplay{
    display: block;
  }
  .bgImage{
    height: 100% !important;
    width: 100vw !important;
    box-shadow: none !important;
  }
  .header{
    font-size: 50px !important;
  top: -16% !important;
  right: 0 !important;
  }
  .imageLogo{
    height: 200px;
  }
  .square_02{
    height: 300px !important;
    width: 100px !important;
    left: 0 !important;
  }
  .container{
    width: 90% !important;
  }
  .square_03{
right: 0 !important;
    height: 200px !important ;
    width: 200px !important;}
  .square_01{
    height: 150px !important;
    width: 150px !important;}
  .inside_circle_01{
    height: 100px !important;
    width: 100px !important;
 
}
.displayOnPhoneDisplay{
display: block !important;
}
.input{
  margin-right: 0 !important;
}
.marginTop10{
  margin-top: 10px;
}
.inside_circle_02{
    height: 50px  !important;
    width: 50px !important;

}
.aboutNew{
  /* margin-bottom: 50px !important; */
}
.imageOpenWeb{
  height: 70vh !important;
}
.centeratphone{
  
 display: flex;
 flex-direction: column;
 justify-content: center;
}
.flexRowToCol{
  flex-direction: column !important;
}
.w50{
  width: 100% !important;
}
.wordsSectionAbout{
  margin-left: 0 !important;
}
}