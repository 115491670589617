.footer{
    min-height: 20vh;
    padding-top: 20px;
    padding-bottom: 20px;

}
.linkfooter{
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 1.4rem;
    margin-top: 20px;
    margin-left: 40px;
}
.bottomFooter{
    width: 100%;
    height: 5vh;
    background-color: #262626;
}
.imagSec{
    max-height: 400px;
    width: 100vw;
}
.input{
    height: 100%;
   height: 30px;
   margin-right: 10px;
}