.flexCol{
    display: flex;
flex-direction: column;
}
.bc{
    background-color: red;
}
.flexRowToCol{
    display: flex;
    flex-direction: row;

}
.flexRowToColRevers{
    display: flex;
    flex-direction: row;
}
.flexRow{
    display: flex;
    flex-direction: row;
}
.center{
    justify-content: center;
    align-items: center;
}
.zindex{
    z-index: 9999999;
}
.margin_top_50{
    margin-top: 50px;
}
.jcenter{
    justify-content: center;
}
.bet{
    justify-content: space-between;
}
.evenly{
    justify-content: space-evenly;
}
.h100{
    height: 100%;
}
.w100{
    width: 100%;
}
.w50{
    width: 50%;
}
.h100vh{
    height: 100vh;
}
.container{
    width: 70%;
    margin: 0 auto;
}
.posrel{
    position: relative;
}
.acenter{
    align-items: center;
}
.aend{
    align-items: end;
}
.h90vh{
    height: 90vh;
}

.drtl{
    direction: rtl;
}

.end{
    justify-content: flex-end;
    align-items: flex-end;
}

.shrink{
    width: 100%;
}
.marginTop150{
    margin-top: 150px;
}
.cw{
    color: white !important;
}
.greenBg{
    
    background-color:#265f5c ;
}
.maxh200{
    max-height: 400px;
}
.bcw{
    background-color: white !important;
}
.whiteBg{
    background-image: url('./images/whitebg.jpg');
    object-fit: cover;
    background-size: cover;
}
.marginTop50 {
    margin-top: 50px;
}
.shrink::after{
    content: '';
    display: block;
    padding-bottom: 100%;
}
a{
    text-decoration: none;
    color: #262626;
}