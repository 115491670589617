/* .imageSection{
    width: 100%;
    height: 50vh;
    background-image: url('../images/new/good.jpg');
    background-size: cover;
    object-fit: cover;
    background-position: center;
} */
.loading_image{
    width: 100%;
   min-height: 300px;
    background-image:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)), url('../images/homeverysmall2.jpg');
    background-size: cover;
    object-fit: cover;
    background-position: center;
}

.animation{
    animation: loadingImage 5s  infinite ;

}
/* @keyframes loadingImage {
    0%{
        background-image:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)), url('../images/homeverysmall2.jpg');

    }
    50%{
        background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.6)), url('../images/homeverysmall2.jpg');

    }
    100%{
        background-image:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)), url('../images/homeverysmall2.jpg');

    }

    
} */

.opacity0{
    opacity: 0;
    transition: all .3s ease;
}
.opacity1{
    opacity: 1;
    transition: all .3s ease;
}
.imageEnd{
    height: 100%;
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
}