.galleryComp{
    min-height: 100vh;
    width: 100%;
    background-image: url('../images/whitebg.jpg');
    background-size: cover;
    object-fit: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}
.galleryOfImage{
}
.overY{
    overflow-y: hidden;
}
