.cercleSocial{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 20px;
    color: black;
}
.cercleSocial:first-child{
    margin-left: 0;
}

.btnMenu{
    padding: 10px 20px 10px 20px;
    border: none;
    background-image:url('../images/logo.png');
    background-size: cover;
    object-fit: cover;
    background-position: center;
    font-size: 1.4rem;
    border-radius: 3px;
   font-weight: 100;

transition: all .3s ease;

}

.btnMenu:hover{
    transform: scale(1.3);
transition: all .3s ease;
}